import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseMiscApiService } from '@common/api/base-misc-api.service';
import { ApplicationRole } from '@apps/auth/models';
import { ShortProfileApi } from '@apps/auth/api/models';

import { DashCountersApi } from './models/back/dash-counters.api';
import { UserApi } from './models/back/user-api';
import { User } from '../domain/models/back/user';
import { AppConstants } from '../../app.constants';
import { MediaTypes } from '@common/services';
import { CreateUserViewModel } from '@apps/back/models/create-user-view-model';

@Injectable({
    providedIn: 'root'
})
export class UsersMiscApiService extends BaseMiscApiService
{
    constructor (http: HttpClient)
    {
        super(http);

        this.segmentRoot = AppConstants.Configuration.apiUrlAuth;
        this.segmentApi = '/auth/users';
    }

    getCounters (): Observable<DashCountersApi>
    {
        return this.get<DashCountersApi>('/count');
    }

    retrieve (id: string): Observable<UserApi>
    {
        return this.get<UserApi>(`/${id}`);
    }

    shortProfile (userName: string): Observable<ShortProfileApi>
    {
        return this.get<ShortProfileApi>(`/${userName}/profile`);
    }

    getUsersByIds (ids: string[]): Observable<UserApi[]>
    {
        return this.post<UserApi[]>('/ids', ids);
    }

    updateUser (user: User): Observable<UserApi>
    {
        return this.put<UserApi>('', user);
    }

    createUser (user: CreateUserViewModel): Observable<UserApi>
    {
        return this.post<UserApi>('', { user });
    }

    updateHabs (userId: string, habs: ApplicationRole[]): Observable<UserApi>
    {
        return this.put<UserApi>(`/${userId}/habs`, habs);
    }

    addHabilitations (userId: string, appRoles: ApplicationRole[]): Observable<UserApi>
    {
        return this.patch<UserApi>(`/${userId}/habs`, appRoles);
    }

    removeHabilitations (userId: string, appRoles: ApplicationRole[]): Observable<UserApi>
    {
        return this.patch(`/${userId}/habs/remove`, appRoles);
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.download(`/export${qs}`, format);
    }

    deactivateUser (userId: string): Observable<boolean>
    {
        return this.put(`/${userId}/deactivate`, {});
    }
}
