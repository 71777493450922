import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseMiscDomainService } from '@common/domain/base-misc-domain.service';
import { arrayMapper } from '@common/operators';
import { AppliRole, PartialUser } from '@apps/back/models';
import { ApplicationRole } from '@apps/auth/models';
import { ShortProfile } from '@apps/auth/domain/models';
import { ShortProfileApi } from '@apps/auth/api/models';
import { MediaTypes } from '@common/services';

import { UsersMiscApiService } from '../api/users-misc-api.service';
import { DashCountersApi } from '../api/models/back/dash-counters.api';
import { Counters, DashCounters } from './models/back/dash-counters';
import { User, DomainUser } from './models/back/user';
import { CreateUserViewModel } from '@apps/back/models/create-user-view-model';

@Injectable({
    providedIn: 'root'
})
export class UsersMiscDomainService extends BaseMiscDomainService
{
    constructor (
        private usersMiscApiService: UsersMiscApiService
    )
    {
        super();
    }

    getCounters (): Observable<DashCounters>
    {
        return this.usersMiscApiService.getCounters()
            .pipe(
                map((dca: DashCountersApi) =>
                    new DashCounters(
                        new Counters(dca.external.total, dca.external.active),
                        new Counters(dca.internal.total, dca.internal.active)
                    )
                )
            );
    }

    get (id: string): Observable<User>
    {
        return this.usersMiscApiService.retrieve(id)
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    shortProfile (userName: string): Observable<ShortProfile>
    {
        return this.usersMiscApiService.shortProfile(userName)
            .pipe(
                map((shortProfileApi: ShortProfileApi): ShortProfile =>
                    ShortProfile.mapperFromApi(shortProfileApi)
                )
            );
    }

    updateUser (user: User): Observable<User>
    {
        return this.usersMiscApiService.updateUser(user)
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    createUser (user: CreateUserViewModel): Observable<User>
    {
        return this.usersMiscApiService.createUser(user)
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    replaceAllHabilitations (userId: string, habs: AppliRole[]): Observable<User>
    {
        return this.usersMiscApiService
            .updateHabs(userId, habs.map((hab: AppliRole) =>
                new ApplicationRole(hab.application, AppliRole.RoleByLevel(hab.role)))
            )
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    addHabilitations (userId: string, appliRoles: AppliRole[]): Observable<User>
    {
        return this.usersMiscApiService
            .addHabilitations(userId, appliRoles.map(ar => new ApplicationRole(ar.application, AppliRole.RoleByLevel(ar.role))))
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    removeHabilitations (userId: string, appliRoles: AppliRole[])
    {
        return this.usersMiscApiService
            .removeHabilitations(userId, appliRoles.map(ar => new ApplicationRole(ar.application, AppliRole.RoleByLevel(ar.role))))
            .pipe(
                map(DomainUser.mapperFromApi)
            );
    }

    getUsersByIds (ids: string[])
    {
        return this.usersMiscApiService.getUsersByIds(ids)
            .pipe(
                arrayMapper(DomainUser.mapperFromApi)
            );
    }

    export (qs: string, format: MediaTypes): Observable<HttpEvent<Blob>>
    {
        return this.usersMiscApiService.export(qs, format);
    }

    deactivateUser (userId: string)
    {
        return this.usersMiscApiService.deactivateUser(userId);
    }
}
